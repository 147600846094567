import axios from 'axios';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

import { GET_ALL_PATIENTS, ADD_PATIENTS, FAILED_TO_GET_ALL_PATIENTS } from '../types/patients'
const showAlert = () => {
  Toastify({
    text: "Saved",
    duration: 3000,
    close: true,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    backgroundColor: "#00a075",
    stopOnFocus: true, // Prevents dismissing of toast on hover
    onClick: function () { } // Callback after click
  }).showToast();

}
const showError = () => {
  Toastify({
    text: "failed",
    duration: 3000,
    close: true,
    gravity: "top", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    backgroundColor: "red",
    stopOnFocus: true, // Prevents dismissing of toast on hover
    onClick: function () { } // Callback after click
  }).showToast();

}
const url = `${process.env.REACT_APP_API}/patient`
const addPatient = (patientInfo) => (dispatch) => {
  axios.post(url, patientInfo).then(result => {
    showAlert();
    dispatch({ type: ADD_PATIENTS, payload: result.data })
    // window.location.reload(false);
  }).catch(error => {
    console.log(error);
    showError();
  })
}

const getAllPatient = () => (dispatch) => {
  axios.get(url).then(result => {
    console.log(result);
    dispatch({ type: GET_ALL_PATIENTS, payload: result.data.data })

  }).catch(error => {
    console.log(error);
    dispatch({ type: FAILED_TO_GET_ALL_PATIENTS, payload: error })
  })
}

export { addPatient, getAllPatient }