import { GET_ALL_PATIENTS } from '../types/patients'
const patients = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ALL_PATIENTS:
      return payload;
    default:
      return state;
  }

}
export default patients;