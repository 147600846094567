import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Fragment } from 'react';
import Home from './components/Home/Home'
import Layout from './components/layout/Auth'

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route path='/'>
            <Layout Component={Home} />
          </Route>
        </Switch>
      </BrowserRouter>
    </Fragment>

  );
}

export default App;
