import React, { useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import { useDispatch } from 'react-redux'

import { getAllPatient } from '../../redux/action-creators/patients';

const Auth = ({ Component }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getAllPatient()(dispatch)
  })
  return (
    <>
      <Navbar />
      <div>
        <Component />
      </div>
    </>
  )
}

export default Auth
