import React from 'react'

const Radio = ({ name, label, onValueChange, state }) => {
  return (
    <div className='form-control'>
      <label className='form-control-label'>{label}</label>
      <div className='radio-group'>
        <label>Yes {''} <input value={'true'} checked={(state[name] && state[name] === 'true') || (state[name] && state[name] === true) ? true : false} name={name} onChange={onValueChange} className='radio' type='radio' /></label>
        <label>No{''} <input value={'false'} checked={(!state[name] || state[name] === 'false') || (!state[name] || state[name] === false) ? true : false} name={name} onChange={onValueChange} className='radio' type='radio' /></label>
      </div>
    </div>
  )
}

export default Radio
