import React, { useState } from 'react'
import './home.scss'
import DataTable from 'react-data-table-component';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import PatienceForm from './PatienceForm'
import { useSelector } from 'react-redux'

const Home = () => {
  const store = useSelector(state => state)
  const [modal, setModal] = useState(false);
  const [newp, setNew] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  }
  const addNewPatient = () => {
    setNew(true);
    setModal(true);
  }
  const selectPatient = async (id) => {
    const patient = await store.patients.filter(curr => curr.id === id);
    setSelectedPatient(patient);
    setModal(true);
    setNew(false)

  }

  // 


  const columns = [
    {
      name: 'Names',
      selector: 'names',
      sortable: true,
    },
    {
      name: 'Date of Birth',
      selector: 'dob',
      sortable: true,
    },
    {
      name: 'Id number',
      selector: '_id',
      sortable: true,
    },
    {
      name: 'Gender',
      selector: 'gender',
      sortable: true,
    },
    {
      name: 'Sector',
      sortable: true,
      selector: 'sector',
    },
    {
      name: 'cell',
      sortable: true,
      selector: 'cell',
    },
    {
      name: 'village',
      sortable: true,
      selector: 'village',
    },
    {
      name: 'Tel',
      sortable: true,
      selector: 'illness',
    },
    {
      name: 'Date of Hospitalization',
      sortable: true,
      selector: 'hospitalization',
    },
    {
      name: 'Date of Exit',
      sortable: true,
      selector: 'exit',
    },
    {
      name: '',
      cell: row => <div><button onClick={() => selectPatient(row.id)}>View</button></div>,
    },
  ];







  // ################################3333333333333

  return (
    <div className='wrapper'>
      <div className='tables-tabs'>
        <div className='tab-item' onClick={addNewPatient}>
          {'Add Records'}
        </div>
      </div>
      <div className='tables-content'>
        <DataTable
          title="List of all Users"
          columns={columns}
          data={store.patients}
          pagination={true} />
      </div>
      {modal ? (
        <Dialog open={modal} onClose={toggleModal} fullWidth maxWidth='lg'>
          <DialogContent>
            <Typography variant='h6'>
              ASSISTANCE FORM
            </Typography>
            <PatienceForm newP={newp} data={selectedPatient} />
          </DialogContent>
        </Dialog>
      ) : ''}

    </div>
  )
}

export default Home
