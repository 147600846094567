import React from 'react'

const TextArea = ({ name, label, onValueChange, state }) => {
  return (
    <div className='form-control'>
      <label className='form-control-label'>{label}</label>
      <textarea value={state[name] ? state[name] : ''} onChange={onValueChange} name={name} className='form-control-field text-field'></textarea>
    </div>
  )
}

export default TextArea
