import React, { useState, useEffect } from 'react'
import './patient.scss'
import { Button } from '@material-ui/core'
import TextInput from '../common/TextInput'
import Radio from '../common/Radio'
import TextArea from '../common/TextArea'
import { addPatient } from '../../redux/action-creators/patients'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router'


const PatienceForm = ({ data, newP }) => {
  const [patientInfo, setPatientInfo] = useState({ material: '' })
  const dispatch = useDispatch();
  const [printUrl, setPrintUrl] = useState(false);
  const changeValue = (e) => {
    setPatientInfo({ ...patientInfo, [e.target.name]: e.target.value });

  }
  const goToprint = () => {
    window.location.href = printUrl;

  }
  useEffect(() => {
    if (data && !newP) {
      setPatientInfo(data[0]);
      setPrintUrl(`${process.env.REACT_APP_API}/${data[0].id}`)
    } else {
      setPrintUrl(false)
      setPatientInfo({ material: '' })
    }
  }, [data, newP])
  const save = () => {
    addPatient(patientInfo)(dispatch)
  }

  return (
    <div class='patient-form'>
      <div class='form-section'>
        <div className='form-section-title'>FULL IDENTITY OF PATIENT / IDENTITE DU PATIENT</div>
        <div className='form-section-inputs'>
          <TextInput state={patientInfo} onValueChange={changeValue} name="names" label='Names ( Noms):' />
          <TextInput state={patientInfo} onValueChange={changeValue} type='date' name="dob" label='Date Of Birth ( Date De Naissance):' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="gender" label='Gender ( Genre)' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="district" label='District ( District) :' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="sector" label='Sector ( Secteur) :' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="cell" label='Cell ( Cellule) :' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="village" label='Village ( Village) :' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="_id" label='ID Number ( No ID) :' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="illness" label='Phone number ( tel) :' />
          <TextInput state={patientInfo} onValueChange={changeValue} type='date' name="hospitalization" label='Date of Hospitalization ( Date D’Hospitalisation):' />
          <TextInput state={patientInfo} onValueChange={changeValue} type='date' name="exit" label='Date Of Exit ( Date de sortie):' />
        </div>
      </div>
      <div class='form-section'>
        <div className='form-section-title'>ASSISTANCE DETAILS / DETAIL D’ASSISTANCE</div>
        <div className='form-section-inputs'>
          <Radio state={patientInfo} onValueChange={changeValue} name='breakfast' label='Breakfast (Petit Dêjener):' />
          <Radio state={patientInfo} onValueChange={changeValue} name='lunch' label='Lunch (Dêjener):' />
          <Radio state={patientInfo} onValueChange={changeValue} name='diner' label='Super (Dîner)' />
          <Radio state={patientInfo} onValueChange={changeValue} name='keepOnSick' label='Keep On Sick ( Service de garde malade ):' />
          <Radio state={patientInfo} onValueChange={changeValue} name='charges' label='Medical Charges ( Charges Médicales ):' />
          <div className='row'>
            <TextArea state={patientInfo} onValueChange={changeValue} name="material" label='Necessary Materials Provided ( Matériels Nécessaires fournis )' />
          </div>
        </div>
      </div>
      <div class='form-section'>
        <div className='form-section-title'>PAYMENT IN RWF / PAYEMENT EN FRW</div>
        <div className='form-section-inputs'>
          <TextInput state={patientInfo} onValueChange={changeValue} name="meal_bill" label='Bill of Meals ( Facture du ration ):' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="keepOnSick_bill" label='Cost of keep on sick service' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="materials_bill" label='Cost of other materials' />
          <TextInput state={patientInfo} onValueChange={changeValue} name="medical_bill" label='Medical bills (Factures médicales):' />
        </div>
        <div style={{ display: 'flex' }}>
          <TextInput state={patientInfo} onValueChange={changeValue} name="hospital_bill" label='Hospitalization bill:' />
          <TextInput style={{ marginLeft: '1.3em' }} state={patientInfo} onValueChange={changeValue} name="home_bill" label='Moving at home costs:' />
        </div>
      </div>
      <div class='row pt-1'>
        <Button onClick={save} style={{ background: 'indigo', color: '#f2f2f2', width: '200px' }}>Save</Button>
        {printUrl ? (<Button onClick={goToprint}>Print</Button>) : ''}
      </div>
    </div>
  )
}

export default withRouter(PatienceForm)
