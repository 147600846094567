import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reducer from './redux/reducers'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(reducer, {}, composeWithDevTools());


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


