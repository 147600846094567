import React from 'react'
import './navbar.scss'


const Navbar = () => {
  return (
    <div className='navbar'>
      navbar
    </div>
  )
}

export default Navbar
