import React from 'react'

const TextInput = ({ name, label, onValueChange, state, style, ...props }) => {
  return (
    <div className='form-control' style={style}>
      <label className='form-control-label'>{label}</label>
      <input {...props} value={state[name] ? state[name] : ''} onChange={onValueChange} name={name} className='form-control-field' />
    </div>
  )
}

export default TextInput
